<!--  -->
<template>
  <div class="banner">
    <div class="carousel">
      <el-carousel ref="carousel" direction="vertical" :interval="cycleTime" arrow="never" @change="changeItem">
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="conBox">
            <div class="left">
              <img class="top-img" :src="item.titleImg" alt="">
              <div class="title">{{ item.title }}</div>
              <div v-for="(o, i) in item.textList" :key="'text' + i" class="text">{{ o }}</div>
              <!-- <div class="btn-box">
                了解更多
                <i class="el-icon-right" />
              </div> -->
            </div>
            <div class="right">
              <img class="big-pic" :src="item.img" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="button-bar">
        <div class="btn-box">
          <div class="banner-btn" :class="showIndex === 0 ? 'active' : ''" @click="setItem(0)">P</div>
          <div v-show="showIndex === 0" class="btn-name">JialandPMS</div>
        </div>
        <div class="btn-box">
          <div class="banner-btn" :class="showIndex === 1 ? 'active' : ''" @click="setItem(1)">A</div>
          <div v-show="showIndex === 1" class="btn-name">AllOne IoTOS</div>
        </div>
        <div class="btn-box">
          <div class="banner-btn" :class="showIndex === 2 ? 'active' : ''" @click="setItem(2)">C</div>
          <div v-show="showIndex === 2" class="btn-name">Carnival Engine</div>
        </div>
        <div class="btn-box">
          <div class="banner-btn" :class="showIndex === 3 ? 'active' : ''" @click="setItem(3)">S</div>
          <div v-show="showIndex === 3" class="btn-name">JialandSMS-toB</div>
        </div>
        <div class="btn-box">
          <div class="banner-btn" :class="showIndex === 4 ? 'active' : ''" @click="setItem(4)">S</div>
          <div v-show="showIndex === 4" class="btn-name">JialandSMS-toG</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  components: {},
  data() {
    return {
      timer: null,
      showIndex: 0,
      cycleTime: 8000,
      list: [{
        titleImg: require('@/assets/jiayuan/JialandPMS.png'),
        title: '嘉园泛资产管理系统',
        textList: [
          'JialandPMS（Property Management System）是嘉园数字平台的泛资产管理子系统，提供基于数字孪生和物联网的空间智能化综合管控及运维服务的标准数据和流程。JialandPMS某种意义上是JDP的支撑骨架，是由包括设备中心、运维中心、数据中心、规则引擎、系统管理、第三方平台管理等标准功能模块组成的产品体系，将根据具体项目需求对一级/二级功能模块进行重组，结合项目业务与JialandSMS等共同组成相应的交付产品。',
          'JDP将各行业的数字化统一从「空间」和「资产」视角出发进行设计，JialandPMS的泛资产（property）含义，涵盖人（组织）、物理设备（物件）、建筑设施（场地）、信息数据（子系统/事件）等所有空间内有形和无形的资产。'
        ],
        img: require('@/assets/jiayuan/banner-pic1.png')
      }, {
        titleImg: require('@/assets/jiayuan/AllOneIoTOS.png'),
        title: '万物生物联网PaaS平台',
        textList: [
          '本质上，企业需要一个性能稳定、扩展灵活且轻量化的系统，能够针对不同的业务形态，将设备和业务的解耦，进而可以更高效地实现各种复杂的场景和业务流程。在JDP整体架构下，作为实现这一需求的AllOne IoT万物生物联网基础平台应运而生。平台主体功能包括设备接入、数据处理、开放共享、资产管理、运维管理服务，并凭借普适、开放、安全、可控、可运维的特征向生态伙伴提供物联网PaaS服务。',
          '从品牌设计上，ALL to ONE，万物归一，而又一生万物，代表着AllOne平台的兼容开放性和JDP架构统一性。logo二元归一的结构脱胎于嘉服云logo，体现并强化了AllOne在嘉园数字平台和相关业务项目中的核心中台定位。“人”、“神经元”等意象代表着万物智联和以人为本的内涵。'
        ],
        img: require('@/assets/jiayuan/banner-pic2.png')
      }, {
        titleImg: require('@/assets/jiayuan/CarnvalEngine.png'),
        title: '嘉年华数字孪生基础引擎',
        textList: [
          '可视化大屏作为JDP数字化成果最直观的展示方式，其核心是嘉年华数字孪生基础引擎Carnival Engine和嘉年华数据可视化标准UI体系Carnival Vision。Carnival Engine提供多种成本和视觉效果的解决方案，包括基于UnrealEngine引擎内核、基于开源WebGL框架和基于第三方图形引擎的集成方案等。',
          'Carnival Engine提供具有基于Web端的多源异构模型及CIM模型可视化和调度分析能力，包含丰富的面向数字模型的开放接口，支持物联网数据集成、构件信息绑定与控制联动等应用需求。在以物联网为核心的集成项目中提供面向设备数字模型的用户管理、权限管理、空间管理、场景管理等服务能力。Carnival Vision主要提供包括自主建模渲染能力和基于中嘉企业VI体系的视觉表达能力。'
        ],
        img: require('@/assets/jiayuan/banner-pic3.png')
      }, {
        titleImg: require('@/assets/jiayuan/JialandSMSToB.png'),
        title: '嘉园企业服务管理系统',
        textList: [
          'JialandSMS-2B（ Service Management System，toB）是嘉园数字平台架构下面向B端（包括B2E、B2B、B2C）的应用服务管理子系统，以云原生和微服务架构提供基于云的数字化企业服务。JialandSMS-2B某种意义上是JDP的皮肉层（应用层），同样是由各行业各业务对应的标准功能模块组成的产品体系（应用商店），将根据具体项目需求对一级/二级功能模块进行重组，结合项目业务与JialandPMS等共同组成相应的交付产品。',
          'JDP在企业服务领域重点关注企业自身业务流程的数字化改造和利用物联网等技术改造对外管理业务两大方面，JialandSMS-2B尤其在物业管理、智慧医院、智能制造、城投建设等领域已有较深厚的积累。'
        ],
        img: require('@/assets/jiayuan/banner-pic4.png')
      }, {
        titleImg: require('@/assets/jiayuan/JialandSMSToG.png'),
        title: '嘉园政务服务管理系统',
        textList: [
          'JialandSMS-2G（ Service Management System，toG）是嘉园数字平台架构下面向G端（包括G2E、G2B、G2C）的应用服务管理子系统，以云原生和微服务架构提供基于云的数字政务服务。JialandSMS-2G某种意义上在JDP架构中具有独立性，区别于企业服务，更多是结合具体政府部门需求独立形成交付产品，这是由数字政务的特殊性决定的。',
          'JDP在数字政务服务领域重点关注行政审批数字化、产业经济政企服务和数字营商环境优化等方面，JialandSMS-2G充分学习和依托浙江省数字政务建设先进经验，以“三个一”为建设目标，即打造“一站式服务、一体化管理、一盘棋决策”的政务数字化改革的统一平台。'
        ],
        img: require('@/assets/jiayuan/banner-pic5.png')
      }]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    setItem(index) {
      this.cycleTime = 30000;
      this.$refs.carousel.setActiveItem(index);
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.cycleTime = 8000;
      }, 30100);
    },
    changeItem(newVal) {
      this.showIndex = newVal;
    }
  }
};
</script>

<style lang='scss' scoped>
.banner {
  height: calc(100vh - 67px);
  max-height: 1080px;
  min-height: 800px;
  background-image: url('../../../assets/jiayuan/jiayuan-bg.png');
  background-size: 'cover';
  background-position: 'center';
  .carousel {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    padding-bottom: 10vh;
    /deep/.el-carousel {
      // width: 100%;
      flex: 1;
      height: 100%;
    }
    /deep/.el-carousel__container {
      width: 100%;
      height: 100%;
    }
    .conBox {
      width: 1070px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding-top: calc(12vh + 67px);
      .left {
        width: 380px;
        height: 100%;
        .top-img {
          width: auto;
          height: 50px;
        }
        .title {
          white-space: nowrap;
          margin-top: 10px;
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 33px;
        }
        .text {
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          color: #666666;
          // text-indent: 28px; // 首行缩进
          margin-top: 15px;
        }
        .btn-box {
          cursor: pointer;
          width: 109px;
          height: 36px;
          position: absolute;
          left: 0;
          bottom: 0;
          margin-top: 48px;
          line-height: 36px;
          text-align: center;
          color: #ffffff;
          background-image: url('../../../assets/home/button2.png');
        }
      }
      .right {
        .big-pic {
          margin-top: 100px;
          width: 648px;
          height: 421px;
        }
      }
    }
    .button-bar {
      width: 130px;
      padding: calc(12vh + 67px) 0 0 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .banner-btn {
        cursor: pointer;
        margin: 0 auto;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.08);
        font-size: 20px;
        font-weight: 600;
        color: #666666;
        &:hover {
          color: #ffffff;
          background: #30a5ff;
        }
      }
      .btn-name {
        margin-top: 15px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        color: #30a5ff;
      }
      .active {
        color: #ffffff;
        background: #30a5ff;
      }
    }
  }
}
</style>
