<!-- 行业生态伙伴 -->
<template>
  <div class="partners">
    <div class="topText">
      <div class="title">行业生态伙伴</div>
    </div>
    <div class="content">
      <img src="@/assets/jiayuan/partner.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.topText {
  height: 200px;
  .title {
    font-size: 40px;
    font-weight: 700;
    color: #0b1d30;
  }
}
.content {
  width: 1236px;
  margin: 0 auto;
  margin-bottom: 80px;
}
</style>
