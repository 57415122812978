// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/solution/foot-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/jiayuan/bottom-bg.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/home/button2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".flex-start[data-v-124b1277]{-webkit-box-pack:start;-ms-flex-pack:start;justify-content:start}.flex-between[data-v-124b1277],.flex-start[data-v-124b1277]{display:-webkit-box;display:-ms-flexbox;display:flex}.flex-between[data-v-124b1277]{-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.flex-align[data-v-124b1277]{-webkit-box-align:center;-ms-flex-align:center;align-items:center}.footers[data-v-124b1277]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:385px;background-size:cover;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center}.conBox[data-v-124b1277]{width:1200px;margin:auto;padding:8.2% 0}.titles[data-v-124b1277]{font-size:48px;font-family:PingFang SC,PingFang SC-Semibold;font-weight:600;color:#0b1d30;text-align:center;height:70px}.group[data-v-124b1277]{padding:0 300px}.group-btn[data-v-124b1277]{width:300px;margin:auto;margin-top:25px}.bottom-box[data-v-124b1277]{width:1920px;height:385px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:distribute;justify-content:space-around}.bottom-box[data-v-124b1277],.bottom-box .conBox[data-v-124b1277]{display:-webkit-box;display:-ms-flexbox;display:flex}.bottom-box .conBox[data-v-124b1277]{width:600px;margin:0 auto;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between}.bottom-box .conBox .text[data-v-124b1277]{font-size:48px;line-height:67px;height:67px;font-weight:600;color:#333}.bottom-box .conBox .btn-box[data-v-124b1277]{cursor:pointer;width:109px;height:36px;line-height:36px;text-align:center;margin-top:15px;color:#fff;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
module.exports = exports;
