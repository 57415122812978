<template>
  <div class="ApplicationEnergize">
    <div class="topText">
      <div class="title">行业应用赋能</div>
    </div>
    <div class="conBox">
      <div class="carousel">
        <div class="content" @mouseleave="interval" @mouseenter="mouseenter">
          <el-carousel ref="carousel" class="left-img" :autoplay="false" arrow="never">
            <el-carousel-item v-for="(item, index) in list" :key="index">
              <img class="nav-item-img" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel>
          <transition-group tag="div" class="right-box" name="fade" mode="out-in">
            <div v-for="(item, index) in list" v-show="showIndex === index" :key="index" class="right-item-box">
              <div class="title-box">
                <img class="title-img" :src="item.titleImg" alt="">
                <div class="title-text">{{ item.title }}</div>
              </div>
              <div class="subtitle">{{ item.subtitle }}</div>
              <div class="text">{{ item.text }}</div>
              <div class="btn-box" @click="$router.push(item.pathUrl)">
                了解更多
                <i class="el-icon-right" />
              </div>
              <div class="logo-box">
                <div class="logo">
                  <img :src="index === 0 ? require('@/assets/jiayuan/pro-light-logo1.png') : require('@/assets/jiayuan/pro-logo1.png')" alt="" @click="setItem(0)">
                  <img :src="index === 1 ? require('@/assets/jiayuan/pro-light-logo2.png') : require('@/assets/jiayuan/pro-logo2.png')" alt="" @click="setItem(1)">
                  <img :src="index === 2 ? require('@/assets/jiayuan/pro-light-logo3.png') : require('@/assets/jiayuan/pro-logo3.png')" alt="" @click="setItem(2)">
                  <img :src="index === 3 ? require('@/assets/jiayuan/pro-light-logo4.png') : require('@/assets/jiayuan/pro-logo4.png')" alt="" @click="setItem(3)">
                </div>
              </div>
              <div class="prev-next">
                <i class="icon-img el-icon-back" @click="prev" />
                <i class="icon-img el-icon-right" @click="next" />
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ApplicationEnergize',
  data() {
    return {
      showIndex: 0,
      timer: null,
      list: [{
        pathUrl: '/solution/health?id=50',
        img: require('@/assets/jiayuan/image1.png'),
        titleImg: require('@/assets/jiayuan/JIaHealth.png'),
        title: 'JDP X 医疗健康',
        subtitle: 'XX市第一人民医院',
        text: '中嘉公司医疗团队拥有医疗行业从业及医疗数字化服务的复合专业经验，以“让智慧病房更智慧”为愿景，与我院联合打造物联网智慧感知信息集成服务平台。平台以病房为空间单元载体，融合院内各种医疗健康系统、数据和业务，实现医疗数据的高效采集和使用，帮助医院优化护理流程，提升医疗质量，提高患者就诊体验，助力我院院区管理和医疗生态链整体数字化建设。'
      }, {
        pathUrl: '/solution/chengtou?id=49',
        img: require('@/assets/jiayuan/image2.png'),
        titleImg: require('@/assets/jiayuan/JIaAEC.png'),
        title: 'JDP X 城投建设',
        subtitle: 'XX市城市建设投资发展有限公司',
        text: '城投与中嘉联手推进企业数字化改革发展，紧密结合日常管理和项目建设需要，推行无纸化办公、智慧化项目监管，基于中嘉JDP架构，以“平台+指挥中心+终端”模式建立公司智慧云平台系统，包括个人办公、工作流、项目管理、知识管理、智慧大屏等内容。平台投入使用后已明显提升了公司管理效能和项目管理智慧化水平。'
      }, {
        pathUrl: '/solution/Scheme?id=48',
        img: require('@/assets/jiayuan/image3.png'),
        titleImg: require('@/assets/jiayuan/JIapark.png'),
        title: 'JDP X 产业园区',
        subtitle: 'XX市国有控股集团有限公司',
        text: '智慧科创小镇作为我市技术创新示范区、成果转移转化应用示范区、发展示范区和产业发展服务中心，具有推动特色产业发展的战略地位。在中嘉公司的全周期支持下，完成了涵盖空间展陈、智能硬件、系统平台、运营机制全方位的智能化数字化升级。基于JDP架构打造的园区综合管控平台和科创在线企业服务门户，将极大助力于园区物业管理和企业招商运营。'
      }, {
        pathUrl: '/solution/mobility?id=51',
        img: require('@/assets/jiayuan/image4.png'),
        titleImg: require('@/assets/jiayuan/JIaMobility.png'),
        title: 'JDP X 停车管理',
        subtitle: 'XX市城市管理局',
        text: '中嘉公司作为技术支持与咨询顾问的角色，共同参与组建城市智慧停车管理公司，基于JDP打造城市级“路内路外一体化、全城联网、融合开放共享”的智慧停车管理系统，达到“一次绑定、全市通停、先离后缴、无感支付”，解决不同场景停车需求，真正实现一码进出所有停车场点，方便群众停车，助力智慧城市建设。'
      }]
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.interval();
  },
  methods: {
    // 定时切换
    interval() {
      this.timer = setInterval(() => {
        this.showIndex < this.list.length - 1 ? this.showIndex++ : this.showIndex = 0;
        this.$refs.carousel.setActiveItem(this.showIndex);
      }, 3000);
    },
    prev() {
      clearInterval(this.timer);
      this.showIndex > 0 ? this.showIndex-- : this.showIndex = (this.list.length - 1);
      this.$refs.carousel.setActiveItem(this.showIndex);
      // this.interval();
    },
    next() {
      clearInterval(this.timer);
      this.showIndex < this.list.length - 1 ? this.showIndex++ : this.showIndex = 0;
      this.$refs.carousel.setActiveItem(this.showIndex);
      // this.interval();
    },
    setItem(index) {
      clearInterval(this.timer);
      this.showIndex = index;
      this.$refs.carousel.setActiveItem(index);
      // this.interval();
    },
    mouseenter() {
      clearInterval(this.timer);
    }
  }
};
</script>
<style lang="scss" scoped>
.ApplicationEnergize {
  background-color: #f9fbff;
  // height: 100vh;
  min-height: 800px;
  .topText {
    height: 193px;
    font-size: 40px;
    font-weight: 600;
    color: #0b1d30;
  }
  .conBox {
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 110px;
  }
}
.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  .content {
    position: relative;
    display: flex;
    .left-img {
      width: 418px;
      height: 529px;
      margin-right: 67px;
      .nav-item-img {
        width: 418px;
        height: 529px;
      }
    }
    .right-box {
      position: relative;
      flex: 1;
    }
    .right-item-box {
      position: absolute;
      left: 0;
      top: 0;
      flex: 1;
      height: 529px;
      .title-box {
        position: relative;
        margin-left: -27px;
        margin-bottom: 20px;
        margin-top: 20px;
        .title-img {
          width: auto;
          height: 94px;
        }
        .title-text {
          position: absolute;
          bottom: 10px;
          left: 27px;
          font-size: 32px;
          font-weight: 600;
          text-align: left;
          color: #333333;
        }
      }
      .subtitle {
        font-size: 20px;
        font-weight: 600;
        text-align: left;
        color: #30a5ff;
        margin-bottom: 16px;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 28px;
      }
      .btn-box {
        position: absolute;
        bottom: 150px;
        left: 0;
        cursor: pointer;
        width: 109px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        background-image: url('../../../assets/home/button2.png');
        color: #ffffff;
      }
      .logo-box {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0;
        img {
          width: 64px;
          height: 64px;
          margin-right: 74px;
        }
      }
      .prev-next {
        position: absolute;
        top: 0;
        right: 0;
        .icon-img {
          font-size: 32px;
          &:active {
            transform: scale(1.2);
          }
          &.el-icon-back {
            margin-right: 40px;
          }
        }
      }
    }
  }
}

/deep/ .el-carousel__container {
  width: 418px;
  height: 529px;
}
/deep/.el-carousel__indicators {
  display: none;
}
.el-carousel__item h3 {
  text-align: center;
  color: #475669;
  font-size: 18px;
  line-height: 300px;
  margin: 0;
}

.fade-enter-active, .fade-leave-active {
  /* opacity 透明度 */
  transition: opacity 1s
}
/* .fade-leave-active, 2.1.8 版本以下 */
.fade-enter, .fade-leave-to  {
  opacity: 0
}

</style>
