<template>
  <div class="UniqueAdvantage">
    <div class="bg-img" />
    <div class="topText">
      <div class="title">嘉园数字平台的独特优势</div>
    </div>
    <div class="conBox" @mouseleave="mouseleave">
      <div v-for="(item, index) in list" :key="index" class="li" :class="activeIndex === index ? 'active' : ''" @mouseenter="mouseenter(index)">
        <div class="logo-box">
          <img class="logo logo-pic" :src="item.logoPic" alt="">
          <img class="logo logo-light-pic" :src="item.logoLightPic" alt="">
        </div>
        <div class="titText">{{ item.titText }}</div>
        <div class="text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UniqueAdvantage',
  components: {},
  data() {
    return {
      activeIndex: null,
      list: [
        {
          logoPic: require('@/assets/jiayuan/logo-pic1.png'),
          logoLightPic: require('@/assets/jiayuan/logo-light-pic1.png'),
          titText: '生态',
          text: 'JDP不想做独吞的角色，而是连接和支撑生态链的使者，在充分吸收先行者的成功经验和自身数字化实践积累下，致力于和客户伙伴联合创新，和数字化共建者们一起沉淀。'
        },
        {
          logoPic: require('@/assets/jiayuan/logo-pic2.png'),
          logoLightPic: require('@/assets/jiayuan/logo-light-pic2.png'),
          titText: '融合',
          text: 'JDP以物联网为核心ICT技术，区别于以设备管理为主的主流物联网平台，AllOne IoT和第三方平台管理模块加大了对客户伙伴的南向开放度，更大程度融合生态中各方的数据和业务。'
        },
        {
          logoPic: require('@/assets/jiayuan/logo-pic3.png'),
          logoLightPic: require('@/assets/jiayuan/logo-light-pic3.png'),
          titText: '开放',
          text: '组件化、模块化、多环境部署能力和集成客户伙伴的平台能力是JDP对客户伙伴开放度的保证，除此之外JDP正在筹划的「嘉园开发者计划」，将邀广大开发者共建生态。'
        },
        {
          logoPic: require('@/assets/jiayuan/logo-pic4.png'),
          logoLightPic: require('@/assets/jiayuan/logo-light-pic4.png'),
          titText: '高效',
          text: '中嘉覆盖“咨询设计、研发集成、工程实施、运维运营”全生命周期产品和服务的生态能力，以及JDP预集成的平台综合能力，支持项目高效且高质量交付。'
        }
      ]
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    mouseenter(index) {
      console.log(index);
      this.activeIndex = index;
    },
    mouseleave() {
      this.activeIndex = null;
    }
  }
};
</script>
<style lang="scss" scoped>
.UniqueAdvantage {
  position: relative;
  .bg-img {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    width: 1618px;
    height: 672px;
    background-image: url('../../../assets/jiayuan/PACSS.png');
  }
  .topText {
    height: 193px;
    font-size: 40px;
    font-weight: 600;
    color: #0b1d30;
  }
  .conBox {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    height: 394px;
    margin: 0 auto;
    margin-bottom: 110px;
    .li {
      box-shadow: 0px 0px 12px 0px rgba(255,255,255,1);
      position: relative;
      padding: 0 36px;
      width: 300px;
      height: 394px;
      background-color: rgba(255, 255, 255, .8);
      transition: box-shadow .3s;
      .logo-box {
        margin: 94px auto 20px;
        transition: margin .3s;
        width: 68px;
        height: 68px;
        position: relative;
        .logo {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .logo-pic {
          opacity: 1;
          transition: opacity .3s;
        }
        .logo-light-pic {
          opacity: 0;
          transition: opacity .3s;
        }
      }
      .title {
        margin: 40px auto 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 75px;
        background: linear-gradient(0deg,#52A6D4 0%, #2382B7 100%);
        border-radius: 50%;
        color: #fff;
        transition: color .3s;
        font-weight: 500;
        font-size: 22px;
        font-family: 'siyuan-medium' !important;
      }
      .titText {
        text-align: center;
        margin-bottom: 12px;
        font-size: 24px;
        font-family: 'siyuan-medium' !important;
        font-size: 24px;
        font-weight: 600;
        color: rgb(51, 51, 51);
        transition: color .3s;
      }
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        height: 56px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transition: height .3s;
      }
    }
    .active {
      z-index: 100;
      box-shadow: 0px 0px 12px 0px rgba(48,165,255,0.16);
      .logo-box {
        margin-top: 67px;
        .logo-pic {
          opacity: 0!important;
        }
        .logo-light-pic {
          opacity: 1!important;
        }
      }
      .titText {
        color: rgb(48, 165, 255);
      }
      .text {
        -webkit-line-clamp: 10;
        height: 200px;
      }
    }
  }
}
</style>
