<template>
  <div class="footers">
    <div class="conBox">
      <div class="group flex-between flex-align">
        <h1 class="titles">数字嘉园 美好家园 </h1>
        <!-- <div class="btn-box" @click="slipPage">免费试用 <i class="el-icon-right" style="margin-left:8px" /> </div> -->
      </div>
      <!-- <div class="group-btn flex-between flex-align">
        <div class="btn-box">{{ btnText }} <i class="el-icon-right" style="margin-left:8px" /> </div>
        <div class="btn-box">免费试用 <i class="el-icon-right" style="margin-left:8px" /> </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    from: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      btnText: ''
    };
  },
  computed: {
  },
  watch: {
    from: {
      handler() {
        this.changeInfo();
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    changeInfo() {
      switch (this.from) {
        case 'Scheme':
          this.btnText = 'JiaPark手册下载';
          break;
        default:
          break;
      }
    },
    slipPage() {
      setTimeout(() => {
        this.$router.push({
          path: '/login'
        });
      }, 800);
    }
  }
};
</script>

<style scoped lang="scss">
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.footers{
  background: url('../../../assets//solution//foot-bg.png');
  height: 385px;
  background-size: cover;
  display: flex;
  justify-content: start;
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
  padding: 8.2% 0px;
}
.titles {
  font-size: 48px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #0b1d30;
  text-align: center;
  height: 70px;
}
.group{
  padding:0px 300px;
}
.group-btn{
  width: 300px;
  margin: auto;
  margin-top: 25px;
}
.btn-box {
  cursor: pointer;
  height: 36px;
  padding: 0px 15px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
  background-image: url('../../../assets/home/button1.png');
}
</style>
