<!-- 嘉园体系 -->
<template>
  <div class="wrapper pageBox">
    <!-- 顶部轮播图 -->
    <Banner />
    <!-- 独特优势 -->
    <unique-advantage />
    <!-- 行业应用赋能 -->
    <application-energize />
    <!-- 行业生态伙伴 -->
    <Partners />
    <!-- 底部 -->
    <footers />
    <!-- <div class="bottom-box">
      <div class="conBox">
        <div class="text">数字嘉园 美好家园</div>
        <div class="btn-box" @click="slipPage">
          免费试用
          <i class="el-icon-right" /></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Banner from './components/Banner.vue';
import UniqueAdvantage from './components/UniqueAdvantage.vue';
import ApplicationEnergize from './components/ApplicationEnergize.vue';
import Partners from './components/partners.vue';
import footers from './components/footer.vue';
export default {
  name: 'JiaYuan',
  components: {
    Banner,
    UniqueAdvantage,
    ApplicationEnergize,
    Partners,
    footers
  },
  data() {
    return {
      bannerList: [{

      }]
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    slipPage() {
      setTimeout(() => {
        this.$router.push({
          path: '/login'
        });
      }, 800);
    }
  }
};
</script>

<style lang='scss' scoped>
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.footers{
  background: url('../../assets//solution//foot-bg.png');
  height: 385px;
  background-size: cover;
  display: flex;
  justify-content: center;
}
.conBox {
  width: 1200px;
  margin: auto;
  padding: 8.2% 0px;
}
.titles {
  font-size: 48px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #0b1d30;
  text-align: center;
  height: 70px;
}
.group{
  padding:0px 300px;
}
.group-btn{
  width: 300px;
  margin: auto;
  margin-top: 25px;
}
.bottom-box {
  width: 1920px;
  height: 385px;
  background-image: url('../../assets/jiayuan/bottom-bg.png');
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .conBox {
    width: 600px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .text {
      font-size: 48px;
      line-height: 67px;
      height: 67px;
      font-weight: 600;
      color: #333333;
    }
    .btn-box {
      cursor: pointer;
      width: 109px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      margin-top: 15px;
      color: #ffffff;
      background-image: url('../../assets/home/button2.png');
    }
  }
}
</style>
